import { RootState } from 'store/reducers'

import { PromotionStoreProps } from './reducer'

export const getPromotionStore = (state: RootState): PromotionStoreProps =>
  state.promotion

export const isRedeemSuccessModalOpen = (state: RootState): boolean =>
  state.promotion.isRedeemSuccessModalOpen

export const getRedeemAmount = (state: RootState): number =>
  state.promotion.redeemAmount ?? 0 // Fallback to 0 if undefined
