import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TextField } from '@labourhub/labour-hub-ds'
import CN from 'classnames'
import { setPayAsYouGoCreditsModalActive } from 'features/payments'
import { setIsHeaderBarDetailsUpdated } from 'store/reducers/user/userSlice'

import { Row, RowWithAlignment } from 'components/atoms/Row'

import { useGetPromotionByCode } from '../api/getPromotionByCode'
import { useRedeemPromotionCode } from '../api/redeemPromotionCode'
import {
  setIsRedeemSuccessModalOpen,
  setPromotion,
  setRedeemAmount,
} from '../store'

type ApplyPromotionRowProps = {
  isModalActive: boolean
}

export const ApplyPromotionRow = ({
  isModalActive,
}: ApplyPromotionRowProps) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const dispatch = useDispatch()
  const [promoCode, setPromoCode] = useState<string>('')
  const [applyPromo, setApplyPromo] = useState(false)
  const [isCreditOnly, setIsCreditOnly] = useState(false)
  const [discountMessage, setDiscountMessage] = useState<string>('')

  const { isHeaderBarDetailsUpdated } = useSelector((state: any) => state.user)

  const { refetch: refetchRedeem } = useRedeemPromotionCode(
    isCreditOnly,
    promoCode,
    (promotion) => {
      dispatch(setPromotion(promotion))
      dispatch(setRedeemAmount(promotion.amount))
      dispatch(setPayAsYouGoCreditsModalActive(false))
      dispatch(setIsRedeemSuccessModalOpen(true))
    },
    ({ response: { data: errData } }: any) => {
      setDiscountMessage(errData.message)
      setIsCreditOnly(false)
      setApplyPromo(false)
    },
  )

  const handleCreditOnlyPromotion = () => {
    setIsCreditOnly(true)
    refetchRedeem()
    dispatch(setPayAsYouGoCreditsModalActive(false))

    dispatch(setIsRedeemSuccessModalOpen(true))
    timeoutRef.current = setTimeout(() => {
      dispatch(setIsHeaderBarDetailsUpdated(!isHeaderBarDetailsUpdated))
    }, 1000)
  }

  const { refetch } = useGetPromotionByCode(
    isModalActive && applyPromo,
    promoCode,
    (promotion) => {
      dispatch(setPromotion(promotion))
      dispatch(setRedeemAmount(promotion.amount))
      if (promotion.isCreditOnly) {
        handleCreditOnlyPromotion()
      }
      setDiscountMessage(`Discount Applied`)
      setApplyPromo(false)
      setIsCreditOnly(false)
    },
    ({ response: { data: errData } }: any) => {
      dispatch(setPromotion(null))
      setDiscountMessage(errData.message)
      setIsCreditOnly(false)
      setApplyPromo(false)
    },
  )

  useEffect(() => {
    setPromoCode('')
    dispatch(setPromotion(null))
  }, [isModalActive])

  useEffect(() => {
    setDiscountMessage('')
    dispatch(setPromotion(null))
  }, [promoCode])

  useEffect(() => {
    if (applyPromo) {
      refetch()
    }
  }, [applyPromo])

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return (
    <Row>
      <RowWithAlignment className='w-1/2' justifyAlignment='start'>
        <TextField
          className='w-3/4 m-2'
          isRequired
          value={promoCode}
          onChange={(e: any) => setPromoCode(e.target.value)}
          placeholder='Promo Code'
          isErrored={discountMessage.includes('Invalid')}
        />
        <Button
          onClick={() => setApplyPromo(true)}
          className='w-[80px] ml-1'
          textTheme='black'
          theme='white'>
          Apply
        </Button>
      </RowWithAlignment>

      <RowWithAlignment className='w-1/2 px-2 ml-6' justifyAlignment='end'>
        {discountMessage && (
          <span
            className={CN(
              'text-extra-small',
              discountMessage.includes('Invalid') ||
                discountMessage.includes('Used')
                ? 'text-Red-700'
                : 'text-Green-700',
            )}>
            {discountMessage}
          </span>
        )}
      </RowWithAlignment>
    </Row>
  )
}
